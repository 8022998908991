import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Samuel Saraiva - Personal Trainer</h1>
        <p>Welcome to your fitness journey hub. Track your progress and get personalized workout plans.</p>
      </header>
    </div>
  );
}

export default App;
